.card {
  margin: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-container {
  margin-bottom: -50px;
  border-radius: 20px;
  overflow: hidden;
}

.skewed {
  width: 250px;
  height: 300px;
  margin: 50px 0 -50px 0;
  border-radius: 30px;
  transform: skewY(15deg);
  overflow: hidden;
}

#img {
  width: 100%;
  height: 100%;
  margin-top: -30px;
  transform: skewY(-15deg);
  background-image: url("https://images.unsplash.com/photo-1613483812364-896455b2b309?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80");
  /* background-image: url("../assets/director.jpg"); */
  background-size: 100%;
  background-position: center;
  transition: all 0.3s ease;
}

#img:hover {
  background-size: 110%;
}

.card-content {
  width: 300px;
  height: auto;
  padding-top: 50px;
  padding-bottom: 10px;
  border-radius: 5px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
}

.card-content h2 {
  font-size: 25px;
  color: #353535;
}

.card-content p {
  font-size: 16px;
  margin-top: 1px;
  color: #353535;
}

.card-content .links {
  width: 80%;
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
}

.card-content .links i {
  color: #1bbbac;
  font-size: 30px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.card-content .links i:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 530px) {
  .skewed {
    width: 200px;
    height: 250px;
  }
  .card-content {
    width: 250px;
  }
  .card-content h2 {
    font-size: 22px;
  }
  .card-content p {
    font-size: 14px;
  }
  .card-content .links i {
    font-size: 25px;
  }
}
@media screen and (min-width: 1700px) {
  /* .skewed {
    width: 300px;
    height: 400px;
  }
  #img {
    transform: skewY(-10deg);
  }
  .card-content {
    width: 500px;
  }
  .card-content h2 {
    font-size: 3.5rem;
  }
  .card-content p {
    font-size: 1.6rem;
  }
  .card-content .links i {
    font-size: 2rem;
  } */
}
