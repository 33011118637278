@import url("https://fonts.googleapis.com/css2?family=Electrolize&display=swap");
.main-carousel {
  margin: 0%;
  padding: 0%;
  max-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.slide-item img {
  min-height: 460px;
  height: 100vh;
}
.slide-content {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
}
.slide-content .slide-text-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: sticky;
  margin: 10% 0 2% 0;
}
.slide-content h1 {
  color: white;
  margin: 0%;
  font-family: "Bitter", serif;
  text-align: center;
}
.slide-content h6 {
  width: 60%;
  color: yellow;
  margin-top: 20px;
  font-size: 0.4em;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "Bitter";
  text-align: center;
}
.hover-arrow {
  display: block;
  position: absolute;
  bottom: 10%;
  left: 50%;
  font-size: 2em;
}
.moving-text {
  max-width: 600px;
  margin: 1em auto;
  overflow: hidden;
  position: relative;
  min-height: 4em;
  font-size: 15px;
  width: 80%;
  color: yellow;
}
.date-new{
  padding: 1rem 0;
  color: rgb(248, 147, 5);
}
.example-right {
  position: absolute;
  white-space: nowrap;
  animation: urmove 9s linear infinite;
}
@-webkit-keyframes urmove {
  0% {
    transform: translate3d(5%, 0, 0);
  }

  100% {
    transform: translate3d(-45%, 0, 0);
  }
}

@keyframes urmove {
  0% {
    transform: translate3d(5%, 0, 0);
  }
  100% {
    transform: translate3d(-45%, 0, 0);
  }
}
.logos {
  justify-self: flex-end;
  display: flex;
  justify-content: space-evenly;
  background: rgba(255, 255, 255, 0.5);
}
.logos .logo {
  width: 25%;
  max-width: 450px;
  display: flex;
  align-items: center;
}

.logos .logo img {
  width: 100%;
  height: auto;
}
/* #################################### */
/* ########## SECTION TWO ############ */
/* #################################### */

#section-two {
  padding-top: 10vh;
}
.sub-text {
  padding: 15px;
  font-size: 0.45em;
  text-align: center;
  font-weight: 500;
  color: yellow;
}
.sub-text table tr td,
.sub-text table {
  border: none;
  color: yellow;
  padding: 0 2rem;
}
.about-iconic {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vh;
}
.about-iconic img {
  width: 400px;
  height: 300px;
  border: 2px solid var(--main-color);
  box-shadow: rgb(17 17 26 / 10%) 0px 8px 24px,
    rgb(17 17 26 / 10%) 0px 16px 48px;
}
.about-text {
  background: whitesmoke;
  width: 50%;
  min-width: 300px;
  height: fit-content;
  padding: 30px;
  font-size: 0.9rem;
  line-height: 160%;
  color: black;
  box-shadow: rgb(17 17 26 / 10%) 0px 8px 24px,
    rgb(17 17 26 / 10%) 0px 16px 48px;
}
.about-text a {
  color: var(--main-color);
}
.about-text .text-heading {
  font-size: 1.5rem;
  font-family: "Bitter";
  font-weight: 800;
  color: var(--main-color);
  margin-bottom: 20px;
}

/* ####################################### */
/* ########## SECOND CAROUSEL ############ */
/* ####################################### */
#speakers-carousel {
  overflow-x: hidden;
}
#speakers-carousel li.slide {
  min-width: 35% !important;
}
.slide-item.speaker {
  padding: 50px;
}

/* #################################### */
/* ########## QUOTE ############ */
/* #################################### */
#quote {
  background: var(--main-color); /* #ff0057 */
  display: flex;
}
.quote-content {
  margin: 50px auto;
  width: 70vw;
  min-width: 300px;
  color: var(--main-color);
  font-size: 2.6em;
  background-color: whitesmoke;
  border-radius: 3px;
  padding: 30px;
}
.quote-content blockquote {
  margin: 40px 20px;
  letter-spacing: 2px;
  line-height: 160%;
  font-family: "Electrolize", sans-serif;
}
blockquote .fas,
blockquote .fa {
  margin: 0 20px;
  transform: translateY(-10px);
}
.quote-content cite {
  margin-left: 70%;
  font-size: 0.5em;
}
/* #################################### */
/* ########## TRACK CARDS ############ */
/* #################################### */
#tracks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vh 0;
}
#tracks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5vh 0;
}
#tracks > * {
  margin: 20px;
}
/* #################################### */
/* ########## Table ################### */
/* #################################### */
#table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  background: var(--main-color);
}
.table-container table {
  width: 700px;
  margin: 20px 0 40px 0;
}
.table-container table td {
  padding: 20px;
  font-size: 1em;
  color: white;
  border-color: white;
}
.table-container.red table td {
  color: #666666;
  border-color: var(--main-color);
}
.date-table-heading {
  font-size: 2.3em;
  margin: 30px 0;
  padding: 10px 15px;
  border-bottom: 4px solid white;
  color: white;
  font-family: "Bitter", serif;
}
#important td {
  font-size: 1.1em;
  font-weight: 600;
}
@media screen and (max-width: 960px) {
  .slide-content {
    font-size: 2.5em;
  }
  #speakers-carousel li.slide {
    min-width: 50% !important;
  }
  .about-iconic img {
    width: 300px;
    height: 250px;
  }
  .about-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 820px) {
  .slide-content {
    font-size: 2.2em;
    align-items: center;
  }

  #speakers-carousel li.slide {
    min-width: 80% !important;
  }

  .quote-content blockquote {
    font-size: 35px;
  }
  .quote-content cite {
    margin-left: 60%;
  }
  .table-container table {
    width: fit-content;
    margin-bottom: 40px;
  }
  .table-container table td {
    padding: 10px;
    font-size: 14px;
  }
}
@media screen and (max-width: 680px) {
  .about-iconic {
    flex-direction: column;
  }
  .about-iconic img {
    width: 340px;
  }
}
@media screen and (max-width: 620px) {
  .slide-content {
    font-size: 2em;
  }
  .slide-content h1 {
    font-size: 1.2em;
  }
  .slide-item img {
    height: 70vh;
  }
  .quote-content blockquote {
    font-size: 30px;
  }

  .quote-content cite {
    margin-left: 50%;
  }
}
@media screen and (max-width: 550px) {
  .slide-content {
    font-size: 2em;
    align-items: center;
  }
  .slide-content h1 {
    width: 90%;
    font-size: 1em;
  }
  .sub-text {
    font-size: 13px;
  }
  .date-table-heading {
    font-size: 1.5em;
  }
  .quote-content blockquote {
    font-size: 20px;
    margin: 0;
  }
  .quote-content cite {
    font-size: 15px;
  }
  .table-container table td,
  #important td {
    padding: 8px;
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  .about-iconic img {
    width: 300px;
  }
}
@media screen and (min-width: 1200px) {
  .slide-content {
    font-size: 3em;
  }

  #table {
    margin-top: 0;
  }
}
@media screen and (min-width: 1700px) {
  /* .about-text .text-heading {
    font-size: 3.5rem;
  }
  .about-text {
    font-size: 1.4rem;
  }
  .about-iconic {
    padding-bottom: 5vh;
  }
  .about-iconic img {
    width: 500px;
    height: 500px;
  }
  .slide-content {
    font-size: 5em;
  } */
}
