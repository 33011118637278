@import url(https://fonts.googleapis.com/css?family=Montserrat:500);

.main-gallery {
  /* background-color: #d3d3d3; */
  background-color: white;
}
.container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 4rem 2rem 2rem;
}

.heading {
  font-family: "Bitter", serif;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  padding: 3.5rem 0;
  color:#1bbbac;
}

.heading span {
  display: block;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  /* Compensate for excess margin on outer gallery flex items */
  margin: -1rem -1rem;
}

.gallery-item {
  /* Minimum width of 24rem and grow to fit available space */
  flex: 1 0 24rem;
  height: 300px;
  /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
  margin: 1rem;
  animation: zoomIn 0.5s ease-in;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit:cover ;
  transition: transform 400ms ease-out;
}

.gallery-image:hover {
  transform: scale(1.15);
}

/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 

*/
@keyframes open-down {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: auto;
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
/* @supports (display: grid) {
  .gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
    grid-gap: 2rem;
    grid-template-rows:400px 400px;
  }

  .gallery,
  .gallery-item {
    margin: 0;
  }
} */
