.footer {
  border-top: 2px solid rgb(156, 154, 154);
  text-align: center;
  padding-bottom: 1.6rem;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
}
.footer_text {
  font-size: 0.8rem;
}
.logos {
  justify-self: flex-end;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.logos .logo {
  width: 20%;
  max-width: 250px;
  display: flex;
  align-items: center;
}

.logos .logo img {
  width: 100%;
  height: auto;
}
@media (min-width: 1700px) {
  /* .footer_text{
        font-size: 1.2rem;
    } */
}

@media (max-width: 500px) {
  .footer_text {
    font-size: 0.4rem;
  }
  .footer {
    padding: 1rem;
  }
}
