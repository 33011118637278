.navbar {
  background-color: transparent;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  margin: 0%;
  padding: 0%;
  font-size: 1rem;
}
.sticky-navbar {
  /* background-color: #fff; */
  background-color: #1bbbac;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 48px;
}
.navbar.sticky-navbar .dropdown.open a {
  color: #1bbbac;
}
.navbar.sticky-navbar div a .navbar.sticky-navbar div .nav-item:hover:after {
  background: white;
}
.navbar.sticky-navbar div .nav-item.selected:after {
  background: white;
}
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}
.nav-logo {
  height: 100%;
}
.nav-logo-container {
  background-image: url("../assets/ICONICt.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 180px;
  height: 100%;
  overflow-y: hidden;
}
.sticky-navbar .nav-logo {
  background-color: var(--main-color);
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 0.5rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 0.7rem;
}
.nav-item.selected:after {
  width: 100%;
  background: white;
}
.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: white;
}
.nav-item .active {
  color: #fff;
  border: 1px solid #fff;
}

.nav-icon {
  display: none;
}

.dropdown {
  position: absolute;
  flex-direction: column;
  background: white;
  color: var(--main-color);
  padding: 15px 20px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
    rgba(17, 17, 26, 0.1) 0px 16px 48px;
  display: none;
  transition: all 2s;
}
.dropdown.open {
  display: flex;
  transition: all 2s;
}
.dropdown a {
  text-decoration: none;
}
.navbar.sticky-navbar div .nav-item .dropdown a:hover,
.dropdown a:hover {
  color: var(--main-color);
}
.nav-links .fa {
  margin-left: 10px;
}
.nav-item.people::after {
  display: none;
}
@media screen and (max-width: 1200px) {
  .navbar {
    overflow-y: visible;
  }
  .navbar.sticky-navbar div .fas {
    color: white;
  }
  .navbar.sticky-navbar div .nav-menu.active {
    background: #1bbbac;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgba(0, 0, 0, 0.7);
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item {
    margin-right: 0;
  }
  .nav-item .active {
    color: #fff;
    border: none;
  }
  .nav-links {
    width: 100%;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: -10px;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
  }
  .dropdown {
    position: relative;
    width: 100%;
    font-weight: 600;
    color: white;
    padding: 10px 0;
  }
  .nav-item.selected:after {
    background: transparent !important;
  }
}
@media screen and (max-width: 1100px) {
  .navbar {
    font-size: 0.85rem;
  }
}
