#contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  padding: 10vh 0;
  /* background: #d3d3d3; */
  background-color: white;
}
.date-table-heading.contacts {
  color: var(--main-color);
  border-bottom: 4px solid var(--main-color);
  margin: 0;
  font-family: "Bitter", serif;
}
.contact-page .date-table-heading.contacts {
  display: none;
}
.contact-page #contacts {
  padding: 5vh 0;
}
.contact-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0 5%;
}
.map-container {
  width: 50%;
  min-width: 300px;
}
.map-container iframe {
  border-radius: 0.8rem;
  width: 90%;
  min-height: 400px;
  margin: 3rem auto;
}
.address,
.contact-heading {
  font-size: 18px;
  font-weight: 800;
  color: #353535;
}
.contact-heading {
  color: var(--main-color) !important;
  padding: 5px 0;
  margin: 10px 0;
  width: 70%;
  border-bottom: 3px solid var(--main-color);
}
.contact-details > * {
  margin: 20px 0;
}
.contact-details > * > * {
  margin: 10px 0;
  line-height: 160%;
  color: #353535;
}
@media (min-width: 1700px) {
  /* .address,
  .contact-heading {
    font-size: 1.3rem;
  }
  .contact-details {
    font-size: 1.1rem;
  }
  .date-table-heading {
    font-size: 2rem;
  } */
  .map-container{
    height: 40%;
  }
}
@media (max-width: 980px) {
  .map-container {
    width: 40%;
  }
}
@media (max-width: 737px) {
  .contact-container {
    flex-direction: column;
    text-align: center;
    font-size: 14px;
  }
  .map-container {
    width: 90%;
  }
  .contact-heading {
    width: 100%;
    margin-top: 20px;
  }
  .address {
    font-size: 14px;
  }
}
