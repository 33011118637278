.expand-tracks {
  min-width: 300px;
  width: 15%;
  min-height: 200px;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: rgb(17 17 26 / 10%) 0px 8px 24px,
    rgb(17 17 26 / 20%) 0px 16px 48px;
  /* background-color: var(--main-color); */
  transition: height 0.5s ease;
  /* background: url("../assets/21770.jpg") no-repeat right top;
  background-size: cover; */
}
.track-heading {
  font-family: "Bitter", serif;
  color: var(--main-color);
  line-height: 1.5;
  margin: 20px;
  font-weight: 600;
  font-size: 1.3rem;
  border: none;
}
.track-list > * {
  color: white;
  transition: all 0.5s;
  font-size: 1rem;
}
.expand-tracks .fa {
  font-size: 30px;
  cursor: pointer;
  color: var(--main-color);
  margin-bottom: 10px;
}
.track-list.two {
  text-align: left;
  background: var(--main-color);
  padding-left: 12%;
  height: 0;
  overflow: auto;
  display: grid;
  flex-direction: column;
  justify-content: space-evenly;
  transition: height 0.5s ease;
}
.track-list.two.expand {
  height: 500px;
  transition: height 0.5s ease;
  padding: 5%;
  padding-left: 12%;
}

.track-list.two::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

/* Track */
.track-list.two::-webkit-scrollbar-track {
  background: hsl(0, 0%, 95%);
}

/* Handle */
.track-list.two::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: hsl(0, 0%, 79%);
}

/* Handle on hover */
.track-list.two::-webkit-scrollbar-thumb:hover {
  background: hsl(0, 0%, 59%);
}
@media screen and (max-width: 550px) {
  .track-heading {
    font-size: 1rem;
    margin: 10px;
  }
  .expand-tracks {
    min-height: 150px;
    min-width: 220px;
  }
  .track-list.two.expand {
    height: 280px;
  }
  .track-list > * {
    font-size: 0.8rem;
  }
}
