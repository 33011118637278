.main {
  /* background-color: #d3d3d3; */
  background-color: white;
  height: 100%;
}
.content {
  padding: 2rem 2rem 0 2rem;
  margin: 2rem auto 0 auto;
  max-width: 1500px;
  text-align: center;
}
.heading {
  text-align: center;
  color: #1bbbac;
  font-size: 3rem;
  border-bottom: 5px solid #1bbbac;
  display: inline-block;
  padding: 0 15px;
}
.content {
  text-align: center;
  color: #666666;
  font-size: 1rem;
  margin: 0 2rem;
  line-height: 1.8;
}
.content2 {
  text-align: justify;
  color: #666666;
  font-size: 1rem;
  margin: 0 2rem;
  line-height: 1.8;
}
.location {
  display: flex;
  justify-content: space-around;
  position: relative;
  margin: 3rem 2rem;
}
.pic {
  height: 400px;
  width: 70%;
  border-radius: 0.8rem;
}
.map {
  border-radius: 0.8rem;
  width: 80%;
  height: 400px;
  margin: 3rem;
}
.hide {
  display: none;
}
.video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.video iframe {
  width: 100%;
  height: 100%;
}
.video i {
  color: white;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.video i:hover {
  color: red;
}
.video i:hover::after {
  color: white;
}
.playBtn {
  width: 100px;
  height: 100px;
  background: radial-gradient(
    rgba(0, 0, 0, 0.8) 60%,
    rgba(255, 255, 255, 1) 62%
  );
  border-radius: 50%;
  position: absolute;
  top: 15%;
  cursor: pointer;
  display: block;
  margin: 100px auto;
  box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.8);
}

/* triangle */
.playBtn::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

/* pulse wave */
.playBtn:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.75);
  top: -30%;
  left: -30%;
  background: rgba(198, 16, 0, 0);
}

@-webkit-keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75),
      0px 0px 25px 10px rgba(255, 255, 255, 0.75);
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}
@media (min-width: 1700px) {
  /* .heading {
    font-size: 2.5rem;
  }
  .pic {
    height: 800px;
    width: 90%;
    margin: 2rem;
  }
  .content {
    font-size: 1.2rem;
    line-height: 2;
  }
  .map {
    height: 800px;
    width: 90%;
  } */
}

@media (max-width: 1024px) {
  .heading {
    font-size: 2rem;
  }
  .pic {
    height: 400px;
    width: 90%;
  }
  .content {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .pic {
    height: 350px;
    width: 100%;
  }
  .map {
    width: 90%;
    margin: 2rem;
  }
}

@media (max-width: 500px) {
  .heading {
    font-size: 1.8rem;
  }
  .main {
    padding-bottom: 2rem;
  }
  .pic {
    height: 200px;
    margin: 0;
  }
  .content {
    padding: 4rem 0.8rem;
    font-size: 0.8rem;
  }
  .map {
    width: 90%;
    height: 300px;
    margin: 0.6rem;
  }
}
