.button {
    min-width: 380px;
    max-width: 400px;
    padding: 12px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    border: none;
    background:white ;
    color:#666666;
    border-radius: 5px;
    margin: 1.2rem 0;
    box-shadow: rgb(17 17 26 / 10%) 0px 2px 6px,
    rgb(17 17 26 / 20%) 0px 4px 12px;
}
