.members {
  /* background-color: #d3d3d3; */
  background-color: white;
}
.content {
  margin: 0 auto;
  padding: 5rem 2rem;
  padding-top: 0rem;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subcontent {
  margin: 0 auto;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
}
.heading {
  margin: 2rem;
  color: #1bbbac;
  font-size: 1.8rem;
  font-family: "Bitter", serif;
  text-decoration: underline;
  text-align: center;
}
.name {
  margin: 0 auto;
  color: #666666;
  margin: 0.4rem;
  margin-bottom: 0.1rem;
  font-size: 1rem;
  display: flex;
  justify-content: space-around;
}

.img1 {
  background: url("../assets/director.jpg");
  height: 150px;
  width: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  object-fit: cover;
  animation: zoomIn 0.5s ease-in;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.img2 {
  background: url("../assets/iit_director.png");
  height: 150px;
  width: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  object-fit: cover;
  animation: zoomIn 0.5s ease-in;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.img3 {
  background: url("../assets/prof.jpg");
  height: 150px;
  width: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
  object-fit: cover;
  animation: zoomIn 0.5s ease-in;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}


@media (min-width: 1600px) {
  .heading {
    font-size: 2.5rem;
  }
  .name {
    font-size: 1.3rem;
  }
}
@media (max-width: 500px) {
  .heading {
    font-size: 1.2rem;
  }
  .name {
    font-size: 0.8rem;
  }
}
